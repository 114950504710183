import React, { useEffect, useState } from 'react'
import { getNews } from '../Service/Api';
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const KinderNews = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData1 = await getNews();
        const topperData = topperData1.filter(item => item.school === "Junior" || item.school === "Both");
        console.log(topperData1)
        setData(topperData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="kinderNews">
        <marquee onMouseOver={(event) => event.currentTarget.stop()} onMouseOut={(event) => event.currentTarget.start()} direction="up" >
          {data.length > 0 ? data.map((item, index) => (
            <div key={index}>
              <p><span>{item.date}</span></p>
              <div className='d-flex'>
                <div>
                  <p>{item.title}</p>
                </div>
                {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank">
                <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/attachment.png" alt="Nirmala School Rajkot" /></LazyLoad> </Link>
                )}
              </div>
            </div>
          )) : (
            <div>
              <p><span>DD.MM.YYYY</span></p>
              <div className='d-flex'>
                <div>
                  <p>Stay Tuned For More Updates</p>
                </div>

              </div>
            </div>
          )}
        </marquee>
        {/* <Link to="/kindergarten-news"><button className='readMore'>View All</button></Link> */}
      </div>
    </>
  )
}

export default KinderNews
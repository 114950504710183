import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
const RulesDesicipline = ()=>{
    return(
        <>
        <Header />
            <Breadcrumb title="Rules Of Discipline" mid="Academic &nbsp; &nbsp;|" tabName="Rules Of Discipline" />
            <div className="innerPage">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12"> 
                            <p>Refined manners, prompt obedience, personal neatness and punctuality are required at all the times.</p>
                            <p>All the students of the school are required to follow the rules and regulations given in the school hand book.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default RulesDesicipline;
import Header from "../Component/Header";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const Visitors = ()=>{
    return(
        <>
        <Header />
            <Breadcrumb title="Visitors" mid="Activities/Life@NCS &nbsp; &nbsp;|" tabName="Visitors" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Various resource persons were invited to conduct seminars for the high school students.</p>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>NAME</th>
                                  <th>NAME OF THE ORG.</th>
                                  <th>TOPIC</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                    <td>Mr. Atul Parekh and his team</td>
                                    <td>Fire and Safety Company</td>
                                    <td>Installed the Fire Hydrant System and also demonstrated its working</td>
                                </tr>
                                  <tr>
                                      <td>Fr, Varghese Alengaden</td>
                                      <td>Rotarian and Founder of Universal Solidarity Movement of Value Education</td>
                                      <td>Teachers as Mentors</td>
                                  </tr>
                                  <tr>
                                      <td>Mr. Suryakumar</td>
                                      <td>Counsellor</td>
                                      <td>Career Counselling ( Class 10 ) Making Mathematics subject interesrting for the students of class 1-8 (for the maths teachers of class 1-8 )</td>
                                  </tr>
                                  <tr>
                                      <td>Dr. Swati Doshi and Dr. Hardik Udeshi</td>
                                      <td>Christ College</td>
                                      <td>Goal Setting' and ' Career Guidance' (Class 10 &amp; 12)</td>
                                  </tr>
                                  <tr>
                                      <td>Sister Lavina</td>
                                      <td>Ssps</td>
                                      <td>Mental Health Care (Class 8)</td>
                                  </tr>
                                  <tr>
                                      <td>Ms Shreya Lalwani</td>
                                      <td>Collins Publication</td>
                                      <td>Literary Devices and Improving Writing Skills (Class 8 &amp; 9)</td>
                                  </tr>
                                  <tr>
                                      <td>Ms. Shradha Anand</td>
                                      <td>Collins Publication</td>
                                      <td>Effective and Innovative Strategies for Teaching English (English Trs.)</td>
                                  </tr>
                              </tbody>
                            </table>
                            <p>We extend our hearty thanks to these guests.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Visitors;
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const About = ()=>{
    return(
        <>
        <Header />
            <Breadcrumb title="Certificates & Awards" mid="Academic &nbsp; &nbsp;|" tabName="Certificates & Awards" />
            <div className="innerPage">
                <div className="container">
                    <div class="row kidsactivities_sec_img">
                        <div class="col-md-4 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sof2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sof2.jpg" class="img-fluid midimg" alt=" Children's Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-md-4 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sof3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sof3.jpg" class="img-fluid midimg" alt=" Children's Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-md-4 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sof4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sof4.jpg" class="img-fluid midimg" alt=" Children's Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-md-4 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sof5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sof5.jpg" class="img-fluid midimg" alt=" Children's Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-md-4 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sof6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sof6.jpg" class="img-fluid midimg" alt=" Children's Convent School Rajkot" /></a> </LazyLoad></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About;
import React, { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { form } from '../Service/Api';
import { getHeader } from "../config"
import { useNavigate } from 'react-router-dom';  
import LazyLoad from 'react-lazyload';
const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
};

const validatePhone = (phone) => {
    const re = /^\d{10}$/;  // Adjust regex according to your needs
    return re.test(phone);
};
const Header = () => {
    const navigate = useNavigate(); 
    const [changeClassVal, setChangeVal] = useState("1");
    const changeClass1 = () => {
         setChangeVal("1");
    }
    const changeClass2 = () => {
         setChangeVal("2");
    }
    useEffect(() => {

    }, [changeClassVal])


    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState('');

    const validateForm = () => {
         let errors = {};
         let formIsValid = true;

         if (!formData.name) {
              errors["name"] = "Name cannot be empty";
              formIsValid = false;
         }
         if (!formData.academicYear) {
              errors["academicYear"] = "Academic Year cannot be empty";
              formIsValid = false;
         }
         if (!formData.class) {
              errors["name"] = "Please Select Class";
              formIsValid = false;
         }

          
         

         if (!formData.phone) {
              errors["phone"] = "Phone number cannot be empty";
              formIsValid = false;
         } else if (!validatePhone(formData.phone)) {
              errors["phone"] = "Invalid phone number, must be 10 digits";
              formIsValid = false;
         }

       

         setErrors(errors);
         return formIsValid;
    };

    const handleChange = (event) => {
         const { name, value } = event.target;
         setFormData(prevState => ({
              ...prevState,
              [name]: value
         }));
    };


    const handleSubmit = async (e) => {
         e.preventDefault();
         if (validateForm()) {
              try {
                   const type = "Admission";
                   const { header } = getHeader(); // Retrieve headers from getHeader function
                   const response = await form(formData, header, type); // Pass the headers to the form function

                   setSubmitStatus('Thank you We will be get back to you soon');

                   setFormData({ name: '', email: '', phone: '', class: '',academicYear: '', message: '' });
                   setTimeout(() => {
                    navigate('/admission-procedure'); // Replace with your desired route
                  }, 1000);

              } catch (error) {
                   console.error('Error submitting form:', error);
              } setErrors({}); // Clear errors on successful submission
         } else {
              setSubmitStatus('Please fill all fields correctly.');
         }
    };

    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleOpenForm = () => setIsFormVisible(true);
    const handleCloseForm = () => setIsFormVisible(false);
    return (
        <>
            <div className="header">

                <div className='container-fluid'>
                    <div className='row'>

                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-12'>
                            <div className="logo">
                                <NavLink to="/">
                                <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/logo.png" alt="Nirmala Convemt School Rajkot" className="img-fluid" /></LazyLoad>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <div className='header_list'>
                                <ul>
                                    <li><a href="tel:0281 - 2966204"><i class="bi bi-telephone-fill"></i> 0281 - 2966204 / 03</a></li>
                                    <li><a href="mailto:ncsrajkot@gmail.com"><i class="bi bi-envelope-fill"></i> ncsrajkot@gmail.com</a></li>
                                    <li><i class="bi bi-geo-alt-fill"></i> Dr Hedgewar Road, Rajkot - 360007</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='dark-box'>
                                <div>
                                    <Link to="/kindergarten">
                                    <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten.gif" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                                    <p>Kindergarten</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/magazine">
                                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine.gif" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                                        <p>Magazine</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/newsletter">
                                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/newsletter.gif" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                                        <p>Newsletter</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="social_media"><ul><li><a href="http://www.youtube.com/@NIRMALACONVENTSCHOOLRAJKOT" target="_blank"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/youtube.png" alt="Nirmala Convent School, Rajkot" /></a></li><li><a href="https://www.instagram.com/nirmalaconventrajkot?igsh=MWxobHVkNmh3MHp4aw==" target="_blank"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/insta.png" alt="Nirmala Convent School, Rajkot" /></a></li><li><a href="#" target="_blank"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/facebook.png" alt="Nirmala Convent School, Rajkot" /></a></li></ul></div>
                        <div className='pphelp'>
                            <a href="https://entab.online/NCSRAJ">
                            <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/pphelp.png" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                            </a>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>

                        </div>
                    </div>
                </div>

                <div className="header-nav">

                    <nav className="navbar navbar-expand-lg">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="main_nav">
                            <ul className="navbar-nav">
                                <li className="nav-item active"> <NavLink className="nav-link" to="/">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/home.gif" alt="Nirmala School Rajkot" /> </LazyLoad>
                                Home </NavLink> </li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/about.gif" alt="Nirmala School Rajkot" /> </LazyLoad>
                                About Us </NavLink>
                                    <ul class="sub-menu">
                                        <li><NavLink to="/about" id="about1">Origin &amp; History</NavLink></li>
                                        <li><NavLink to="/our-patronees" id="about2">Our Patroness</NavLink></li>
                                        <li><NavLink to="/school-parliament" id="about3">School Parliament</NavLink></li>
                                        <li><NavLink to="/headmistress" id="about5">Headmistress Message</NavLink></li>
                                        <li><NavLink to="/vision-mission" id="about4">Motto &amp; Vision-Mission</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/admission.gif" alt="Nirmala School Rajkot" /></LazyLoad>
                                      CBSE Disclosure</NavLink>
                                    <ul class="sub-menu">
                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/mandatory-public-disclosure.pdf" target="_blank">Mandatory Public Disclosure </NavLink></li>
                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/General-Information.pdf" target="_blank">General Information</NavLink></li>
                                        <li><NavLink to="#">Document &amp; Information</NavLink>
                                            <ul class="sub-menu">
                                                <li><a href="#">Affiliation Letter &amp; Upgradation Letter</a>
                                                    <ul class="sub-menu">
                                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Approval-of-Middle-Class-Syllabus(1toVIII).pdf" target="_blank"> Approval of Middle Class Syllabus ( Class 1 to VIII )</NavLink></li>
                                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/First-Composite-affiliation-upto-secondary-level.pdf" target="_blank"> First Composite affiliation upto Secondary level </NavLink></li>
                                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Extension-of-Affiliation.pdf" target="_blank"> Extension of Affiliation upto Secondary Level</NavLink></li>
                                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Upgradatiion-to-senior-secondary-school.pdf" target="_blank"> Upgradation to Senior Secondary Level </NavLink></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/trust-registration-certificate.pdf" target="_blank">Trust Registration Certificate</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/NOC.pdf" target="_blank">NOC</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/recognition-letters.pdf" target="_blank">Recognition Letter</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/building-safety-certificate.pdf" target="_blank">Building Safety Certificate</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/FIRE-NOC.pdf" target="_blank">Fire Safety Certificate</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/SELF-CERTIFICATE.pdf" target="_blank">Self Certificate</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Health-and-Sanitation-certificate.pdf" target="_blank">Health &amp; Sanitation Certificate</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="#">Result &amp; Academics</NavLink>
                                            <ul class="sub-menu">
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/CBSE-Fees-Details.pdf" target="_blank">Fee Structure</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/academic-calendar.pdf" target="_blank">Annual Academic Calendar</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/SMC.pdf" target="_blank">Management Committee (SMC)</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Class-X-Result-Analysis.pdf" target="_blank">Class X Result Analysis</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Class-XII-Result-Analysis.pdf" target="_blank">Class XII Result Analysis</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="#">Staff (Teaching)</NavLink>
                                            <ul class="sub-menu">
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Staff-Information.pdf" target="_blank">Staff Information</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/STAFF-DETAILS.pdf" target="_blank">Staff Details</NavLink></li>
                                                <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/Parent-Teacher-Association.pdf" target="_blank">Parent Teacher Association</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="https://webapi.entab.info/api/image/NCSR/public/pdf/School-Infrastructure.pdf" target="_blank">School Infrastructure</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/"><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/academics.gif" alt="Nirmala School Rajkot" /></LazyLoad> Academics</NavLink>
                                    <ul class="sub-menu">
                                        <li><NavLink to="/certificates-awards" id="academic1">Certificates &amp; Awards</NavLink></li>
                                        <li><NavLink to="/administration" id="academic2">Administration</NavLink></li>
                                        <li><NavLink to="/admission-procedure" id="academic3">Admission Procedure</NavLink></li> 
                                        <li><NavLink to="/year-planner" id="academic5">Year Planner</NavLink></li>
                                        <li><NavLink to="/school-hours" id="academic6">School Hours</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">
                                    <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/activity.gif" alt="Nirmala School Rajkot" /></LazyLoad> Activities/Life@NCS
                                    </NavLink>
                                    <ul class="sub-menu">
                                        <li><NavLink to="/classroom-activity" id="activities1">Classroom Activities</NavLink></li>
                                        <li><NavLink to="/co-curricular" id="activities2"> Co-curricular</NavLink></li>
                                        <li><NavLink to="/social-community-service" id="activities3"> Social and Community Service</NavLink></li>
                                        <li><NavLink to="/sports" id="activities4">Sports</NavLink></li>
                                        <li><NavLink to="/visitors" id="activities6">Visitors</NavLink></li>
                                        <li><NavLink to="/coScholastic" id="activities7">Co-Scholastic Activity</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/rules-desicipline">
                                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/rules.gif" alt="Nirmala School Rajkot" /></LazyLoad> Rules & Regulations
                                    </NavLink>
                                    {/* <ul class="sub-menu">
                                        <li><NavLink to="/certificates-awards" id="academic1">Certificates &amp; Awards</NavLink></li>
                                        <li><NavLink to="/administration" id="academic2">Administration</NavLink></li>
                                        <li><NavLink to="/admission-procedure" id="academic3">Admission Procedure</NavLink></li>
                                        <li><NavLink to="/rules-desicipline" id="academic4">Rules</NavLink></li>
                                        <li><NavLink to="/year-planner" id="academic5">Year Planner</NavLink></li>
                                        <li><NavLink to="/school-hours" id="academic6">School Hours</NavLink></li>
                                    </ul> */}
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"   onClick={handleOpenForm}>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/student-admission.gif" alt="Nirmala School Rajkot" /> </LazyLoad>
                                    Student Admission </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/contact.gif" alt="Nirmala School Rajkot" /> </LazyLoad>
                                    Contact Us </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            {isFormVisible && (
               <div className='admission-block'>
                    <form onSubmit={handleSubmit}>
                         <center><h3>Admission Enquiry</h3></center>
                         <div className='close' onClick={handleCloseForm}><i class="bi bi-x"></i></div>
                         <div>
                              <label>Student Name <span>*</span></label>
                              <input type="text" placeholder="Full Name" name="name" value={formData.name} onChange={handleChange} />
                              {errors.name && <div className="text-danger">{errors.name}</div>}
                         </div>
                         <div>
                              <label>Email Id (Parent's)</label>
                              <input type="email" placeholder="Email Id" name="email" value={formData.email} onChange={handleChange} />
                              {errors.email && <div className="text-danger">{errors.email}</div>}
                         </div>
                         <div>
                              <label>Mobile Number (Parent's) <span>*</span></label>
                              <input type="tel" placeholder="Mobile Number" name="phone" value={formData.phone} onChange={handleChange} />
                              {errors.phone && <div className="text-danger">{errors.phone}</div>}
                         </div>
                         <div>
                              <label>Academic Year <span>*</span></label>
                              <select name="academicYear" value={formData.academicYear}  onChange={handleChange}>
                                   <option>Select Academic Year</option>
                                   <option>2024-2025</option>
                                   <option>2025-2026</option>
                              </select>
                              {errors.academicYear && <div className="text-danger">{errors.academicYear}</div>}
                         </div>
                         <div>
                              <label>Admission For Class <span>*</span></label>
                              <select name="class" value={formData.class} onChange={handleChange}>
                                   <option>Select Class</option>
                                   <option>1</option>
                                   <option>2</option>
                                   <option>3</option>
                                   <option>4</option>
                                   <option>5</option>
                                   <option>6</option>
                                   <option>7</option>
                                   <option>8</option>
                                   <option>9</option>
                                   <option>10</option>
                                   <option>11</option>
                                   <option>12</option>
                              </select>
                              {errors.phone && <div className="text-danger">{errors.phone}</div>}
                         </div>
                         <div>
                              <label>Message</label>
                              <textarea row="4" name="message" value={formData.message} onChange={handleChange}></textarea>
                         </div>
                         <div>
                              <input type="submit" value='SUBMIT'></input>
                              {submitStatus && <div className="feedback">{submitStatus}</div>}
                         </div>
                    </form>
               </div>
               )}
        </>
    )
}

export default Header

import React from "react";
import { Link } from 'react-router-dom' 
import Footer from '../Component/Footer' 
const Breadcrumb = ({title, mid, tabName}) => {
    return(
        <>
  
        <div className="breadcrumb">
            <h1>{title}</h1>
            <ul>
                <li><Link to="/">Home</Link> &nbsp; &nbsp;|</li>
                <li>{mid}  </li>
                <li>{tabName}</li>
            </ul>
        </div>
       
        </>
    )
}
export default Breadcrumb
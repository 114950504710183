import Header from "../Component/Header";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Career = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="ERP Software" tabName="ERP Software" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div class="erpboxdesc">
                                <p class="details"> <Link to="https://www.entab.in/" target="_blank"> Entab Infotech Pvt Ltd.,</Link> a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced ERP:  <Link to="https://www.entab.in/" target="_blank"> 'CampusCare®' </Link> and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the communication portal and mobile apps. <br />The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with <Link to="https://www.entab.in/" target="_blank">  Entab: CampusCare® </Link> and look forward to receive the much more in technological front. </p>
                                <div class="erpboxdescpopup row">
                                    <div class="col-md-4 erppopup">
                                        <h4 class="imgheading"> Manage Data</h4>
                                        <LazyLoad> <img id="myImg" src="https://webapi.entab.info/api/image/NCSR/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" class="midimg animation center-block img-fluid" data-bs-toggle="modal" data-bs-target="#staticBackdrop" /></LazyLoad>
                                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" class="img-fluid" /></LazyLoad>
                                                        <h5>School Management System, Management ERP Software</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 erppopup">
                                        <h4 class="imgheading">Parent Portal</h4>
                                        <LazyLoad><img id="myImg1" src="https://webapi.entab.info/api/image/NCSR/public/Images/Parent-Portal.gif" alt="Entab Parent Portal, School Software" class="midimg animation center-block img-fluid" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" /></LazyLoad>
                                        <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                    <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Parent-Portal.gif" alt="Entab Parent Portal, School Software" class="img-fluid" /></LazyLoad>
                                                        <h5>Entab Parent Portal, School Software</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 erppopup">
                                        <h4 class="imgheading">Communication Portal</h4>
                                        <LazyLoad><img id="myImg2" src="https://webapi.entab.info/api/image/NCSR/public/Images/communication.gif" alt="School Communication Portal" class="midimg animation center-block img-fluid"
                                            data-bs-toggle="modal" data-bs-target="#staticBackdrop2" /></LazyLoad>
                                        <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                    <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/communication.gif" alt="School Communication Portal" class="img-fluid" /></LazyLoad>
                                                        <h5>School Communication Portal</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-3">
                        <div class="erpboximg">
                                    <h4 class="imgheading">CampusCare</h4>
                                <center><a href="#"> 
                                <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Mobile-slides.gif" className="img-fluid center-block" alt="School ERP Software, Mobile App, School Management Software, Software for School" /></LazyLoad>
                                </a> 
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Career;
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getVideo } from '../Service/Api'
import LazyLoad from 'react-lazyload';
const VideoTestimonial = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData1 = await getVideo();
        const eventData = eventData1.filter(item => item.school === "Senior");
        console.log(eventData)
        setData(eventData);
      } catch (err) {
        console.error("Error:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
     
  };
  const getYouTubeEmbedUrl = (url) => {
    if (url.includes("youtu.be")) {
      // Convert "https://youtu.be/..." to "https://www.youtube.com/embed/..."
      return url.replace("youtu.be/", "www.youtube.com/embed/");
    } else if (url.includes("youtube.com/watch")) {
      // Convert "https://www.youtube.com/watch?v=..." to "https://www.youtube.com/embed/..."
      return url.replace("watch?v=", "embed/");
    }
    return url; // Return the original URL if it's not a YouTube link
  };

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading videos</p>}
      {!loading && data.length === 0 && (
        <>
          <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/youtube-video.png" class="img-fluid" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
          <p>City Topper : Ananya Agarwal Class 2023, Humanities 98.6%</p>
        </>
      )}
      {!loading && data.length > 0 && (
        <Slider {...settings}>
          {data.length > 0 ? data.map((item, index) => (
            <div className='item' key={index}>
              {item.videoUrl.includes("youtube.com") || item.videoUrl.includes("youtu.be") && (
                <iframe
                  width="100%"
                  height="250"
                  src={getYouTubeEmbedUrl(item.videoUrl)}
                  title={item.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              <p>{item.title}</p>
            </div>
          )) : (
            <>
              <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/youtube-video.png" class="img-fluid" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
              <p>City Topper : Ananya Agarwal Class 2023, Humanities 98.6%</p>
            </>
          )}
        </Slider>
      )}
    </>
  );
};

export default VideoTestimonial;

import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
const SchoolHours = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="School Hours" mid="Academic &nbsp; &nbsp;|" tabName="School Hours" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Summer Schedule</h3>
                            <p><b>Regular Days:</b></p>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td>Class</td>
                                        <td>Assembly</td>
                                        <td>Recess</td>
                                        <td>Dismissal</td>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Std. 4 to 12 </td>
                                        <td>07:30 </td>
                                        <td>10:25 to 10:50 </td>
                                        <td>13:30</td>
                                    </tr>
                                    <tr>
                                        <td>Std. 1 to 3 </td>
                                        <td>08:00 </td>
                                        <td>10:25 to 10:50 </td>
                                        <td>12:50</td>
                                    </tr>
                                    <tr class="tablebg">
                                        <td colspan="4">Saturday</td>
                                    </tr>
                                    <tr>
                                        <td>Std. 4 to 12 </td>
                                        <td>07:30 </td>
                                        <td>10:15 to 10:40 </td>
                                        <td>12:30</td>
                                    </tr>
                                    <tr>
                                        <td>Std. 1 to 3 </td>
                                        <td>08:00 </td>
                                        <td>10:15 to 10:40 </td>
                                        <td>11:55</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>Winter Schedule</h3>
                            <p><b>Regular Days:</b></p>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td>Class</td>
                                        <td>Assembly</td>
                                        <td>Recess</td>
                                        <td>Dismissal</td>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Std. 4 to 12 </td>
                                        <td>07:45 </td>
                                        <td>10:40 to 11:05 </td>
                                        <td>13:45 </td>
                                    </tr>
                                    <tr>
                                        <td>Std. 1 to 3 </td>
                                        <td>08:15 </td>
                                        <td>10:40 to 11:05 </td>
                                        <td>13:05 </td>
                                    </tr>
                                    <tr class="tablebg">
                                        <td colspan="4">Saturday</td>
                                    </tr>
                                    <tr>
                                        <td>Std. 4 to 12 </td>
                                        <td>07:45 </td>
                                        <td>10:30 to 10:55 </td>
                                        <td>12:45 </td>
                                    </tr>
                                    <tr>
                                        <td>Std. 1 to 3 </td>
                                        <td>08:15 </td>
                                        <td>10:30 to 10:55 </td>
                                        <td>12:10</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>Office Timings</h3>
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Principal’s Office – Visiting Hours </td>
                                        <td>8:00 to 8:30 AM </td>
                                        <td>12:00 to 01:00 PM</td>
                                    </tr>
                                    <tr>
                                        <td>School Office Hours </td>
                                        <td>8:00 to 13:00 </td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default SchoolHours;
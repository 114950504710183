import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const Career = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Career" tabName="Career" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">                       
                        <div className="col-md-7">  
                            <p><b>For the following subjects:</b></p>
                            <ul>
                                <li>Physics, Chemistry, Mathematics, Microbiology.(Post Graduates with B. Ed or interested in pursuing B. Ed)</li>
                                <li>Social Studies & English (B.A, B.Ed. or M.A, B.Ed.)</li>
                                <li>Physical Education (M.P.Ed.)</li>
                                <li>Kindergarten: (Graduation with B.Ed. or Montessori)</li>
                            </ul>
                            <p><b>How To Apply for any of the above position, please send your resumes</b></p>
                            <p><b>To CV </b> - <a href="mailto:  hrschoolrecruitment2024@gmail.com"> hrschoolrecruitment2024@gmail.com</a></p>
                            <p><b>OR Contact </b><a href="tel: 9586724824"> 9586724824</a></p>
                            <p><strong>Note:</strong></p>
                            <p> Salary based on qualification, experience and performance in demo class (Attractive salary for deserving candidates)</p> 
                            <div class="clr"></div>
                            <p><b>Managing Trustee</b></p>
                            <p>NIRMALA CONVENT SCHOOL<br /> Nirmala Road, Rajkot - 360 001</p>
                        </div> 
                        <div className="col-md-5">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/career.jpg" className="img-fluid" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                        </div>
                        <div class="col-lg-12">
                           
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Career;
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const VisionMission = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="School Motto & Vision-Mission" mid="About Us &nbsp; &nbsp;|" tabName="School Motto & Vision-Mission" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
                            <div className="mt-4">
                                <h3>Meaning of our emblem</h3>
                                <p>The logo of our school signifies "Unity is strength". The binding hands signify equal opportunities for all to acquire skills and for individual judgement. Nirmala Convent School aims at creating a sense of moral and social responsiblities, developing human personality and strengthening respect for human rights.</p>
                                <p>Faithful to the motto "LOVE IN SERVICE" the school tries to reach out to the society irrespective of caste, creed and financial status. It promotes through various activities, understanding and friendship for a better tomorrow.</p>
                                <p>The teaching and non-teaching staff at Nirmala take great efforts to maintain the standard of the school in all sphere through their selfless service.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <h3>Our Vision</h3>
                                <p>A productive, harmonious, just and humane society. And Add the school emblem on that page besides the paragraph meaning of school emblem.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <h3>Our Mission</h3>
                                <p>To build an integral and value based community through our education policies and actions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default VisionMission;
import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/NCSR`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/NCSR`);
    console.log(data)
    return data;
} 
export const getTestimonial = async() => {
    const { data } = await axios.get(API_URL + `/api/testimonials/NCSR`);
    console.log(data)
    return data;
} 

export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/NCSR`);
    console.log(data)
    return data;
}

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/NCSR`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/NCSR`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/NCSR`);
    return data;
} 
export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/NCSR`);
    console.log(data)
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/all/NCSR`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/NCSR`,getHeader());
    console.log(data)
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/NCSR`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/NCSR`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/NCSR`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/NCSR`, getHeader());
    return data;
} 
  
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission);
    const { data } = await axios.post(API_URL + `/api/tc/NCSR`,{name,admission},getHeader());
    return data;
}

export const form = async (formData, header, type) => {    
    const { data } = await axios.post(API_URL + `/api/form/NCSR/${type}`, formData, header);
    return data;
}
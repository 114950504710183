import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const Campus = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Campus" tabName="Campus" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p> A conducive environment which inspires all to appreciate and adopt what is natural, simple and true. </p>
                            <p> The spacious, well lit classrooms with child friendly furnitures, facilitate teaching learning process. </p>
                            <p> The school is concerned about the safety of all the children in the premises. To address this concern, we mantain round the clock CCTV surveillance and all entrances and exits are guarded by security personnel. </p>
                            <p><b>NCS has a very good infrastructure with</b></p>
                        </div>
                    </div>
                    <div class="row campusbox">
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-0.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-0.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">MAIN OFFICE</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-1.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-1.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">MAIN OFFICE RECEPTION</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-2.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-2.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">MAIN BUILDING</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-3.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-3.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PRE-PRIMARY BUILDING</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-4.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-4.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PRIMARY BUILDING</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-5.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-5.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PRE-PRIMARY OFFICE</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-6.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-6.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PRIMARY OFFICE</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-7.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-7.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">GREEN CAMPUS</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-8.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-8.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">KINDERGARTEN PLAY AREA</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-9.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-9.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PLAY GROUND</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-10.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-10.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">SPORTS OFFICE</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-11.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-11.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">ASSEMBLY GROUND</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-12.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-12.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PRE-PRIMARY ASSEMBLY HALL</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-13.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-13.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">MAIN HALL</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-14.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-14.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">SMART CLASS</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-15.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-15.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">CLASSROOM 2</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-16.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-16.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">CLASSROOM 3</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-17.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-17.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">OUTDOOR CLASS</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-18.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-18.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PRIMARY LIBRARY</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-19.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-19.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">MUSIC ROOM</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-20.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-20.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">AUDIO VISUAL ROOM</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-21.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-21.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">COMPUTER LAB 1</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-22.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-22.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">COMPUTER LAB 2</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-23.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-23.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">COMPUTER LAB 2</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-24.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-24.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">PHYSICS LAB</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-25.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-25.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">CHEMISTRY LAB</h6>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-26.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-26.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">BIOLOGY LAB</h6>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-27.jpg">
                            <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ncs-27.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a></LazyLoad>
                            <h6 class="campus-head">LANGUAGE LAB</h6>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Campus;
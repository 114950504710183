import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const CBSE = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Welcome to NCSR CBSE" mid="CBSE Board &nbsp; &nbsp;|" tabName="Welcome to NCSR CBSE" />
            <div className="innerPage cbse-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Nirmala Convent school is affiliated to CBSE. The school, a symbol of service and quality education was founded on June 14, 1963. It has currently more than 3300 students, over a hundred well qualified and dedicated teaching staff and an able body of administration. The vision of Nirmala is a productive, harmonious, just and humane society. To achieve this vision, the school strives to form students who are academically excellent, emotionally balanced, culturally rooted, socially responsible and spiritually mature. Along with value based learning,</p>
                        </div>
                        <div className="col">
                            <div>
                                <Link to="/magazine">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                    <p>Magazine</p>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <Link to="/tc">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/tc.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                    <p>TC Issued</p>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <Link to="/campus">
                                <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/school.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                    <p>Campus</p>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                            <Link to="/events">
                            <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/events.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                <p>CBSE Board Events</p>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                            <Link to="/news">
                            <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/newspaper.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                                <p>CBSE Board News</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default CBSE;
import KindergartenMenu from "../Component/kindergartenMenu";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const CulturalProgramm = () => {
    return (
        <>
          <KindergartenMenu />
            <Breadcrumb title="Cultural Program" mid="Kindergarten &nbsp; &nbsp;|" tabName="Cultural Program" />
            <div className="innerPage">
                <div className="container">
                    <div class="row">
                        <div className="col-md-12">
                            <p>There is a plethora of activities, providing them to explore and experience a variety of stimulating opportunities. Different competition and cultural activities are held the year round, to develop their hidden talents.</p>
                        </div>
                    </div>
                    <div class="row kidsactivities_sec_img min-height-section">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-1.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-2.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-3.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-4.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-5.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-6.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-7.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-7.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-8.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-8.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-9.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-9.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-10.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-10.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-11.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-11.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-12.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-12.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-13.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-13.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-14.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-14.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-15.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-15.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-16.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-16.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-17.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-17.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-18.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-18.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-19.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-19.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-20.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-20.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-21.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-21.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-22.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-22.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-23.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-23.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-24.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-24.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-25.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-25.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-26.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-26.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-27.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-27.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-28.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-28.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-29.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-29.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-30.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-30.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-31.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-31.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-32.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-32.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-33.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-33.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-34.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-34.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-35.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-35.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-36.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-36.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-37.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-37.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-38.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-38.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-39.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-39.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-40.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-40.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-41.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-41.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-42.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-42.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-43.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-43.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-44.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-44.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-45.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/cultural-45.jpg" class="img-fluid midimg" alt="Children's Convent School Rajkot" /></a></LazyLoad> </div>


                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default CulturalProgramm;
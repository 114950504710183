import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const ParentPortal = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Parent Portal Guidelines" tabName="Parent Portal Guidelines" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 class="guideheading">Fees payment process</h4>
                            <p class="details">1. Login to the portal <a href="https://entab.online/ncsraj" class="parent-link" target="_blank">https://entab.online/ncsraj</a> using the login credentails</p>
                            <p class="details">2. Click on Pay Online button on left menu bar</p>
                            <p class="details">3. Select the Installment and click on Proceed to Pay button</p>
                            <p class="details">4. Click on Pay now button and select your convenient mode of payment (Debit/Credit cards, Internet Banking, UPI/QR and Wallet).</p>
                            <p class="details">5. After successful payment, you will be redirected back to parent portal where you can download the free receipts of payment made from Fee section.</p>
                            <h4 class="guideheading">Mobile App</h4>
                            <p class="details">1. Via Mobile App</p>
                            <p class="details">2. Download App</p>
                            <p class="details">iOS: CampusCare10X on App Store: - <a href="https://apps.apple.com/in/app/campuscare-10x/id1611283814" class="parent-link" target="_blank"> <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/apple.png" width="80px" alt="Nirmala Convent School, Rajkot" /></LazyLoad></a> <br />
                                Android: CampusCare10X on Google Play - <a href="https://play.google.com/store/apps/details?id=com.entab.learninglab" class="parent-link" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/playstore.png" width="80px" alt="Nirmala Convent School, Rajkot" /></LazyLoad></a> </p>
                            <h4 class="guideheading">Payment Steps: </h4>
                            <p class="details">1. Install and open the CampusCare10X app. Enter the school code: <a href="https://entab.online/ncsraj" target="_blank">ncsraj </a> .</p>
                            <p class="details">2. Log in successfully, then tap on the Fees icon.</p>
                            <p class="details">3. Choose the installment and press Proceed to Pay.</p>
                            <p class="details">4. Select Pay now and your payment method.</p>
                            <p class="details">5. Post-payment, the app redirects you for receipt download.</p>
                            <div class="assistance">
                                <h4 class="guideheading">Please call or write for assistance:</h4>
                                <p class="details">
                                    <strong> <a href="https://www.entab.in/contact-us.html" target="_blank">PARENT HELP DESK</a></strong>
                                    ( 8 AM to 8:00 PM ) - <strong>Tele No.</strong> 011- 43193333-Ext : 5,<strong> E-Mail :</strong>
                                    <strong><a href="mailto:parentdesk@entab.in" class="parent-link" target="_blank">parentdesk@entab.in</a></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ParentPortal;
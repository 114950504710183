import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const AdmissionProcedure = () => {
    return (
        <>      
        <Header />
            <Breadcrumb title="Admission Procedure" mid="Academic &nbsp; &nbsp;|" tabName="Admission Procedure" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-8">
                            <h3>Admission to  <span>Holy Spirit Pre-Primary School</span></h3>
                            <ul className='list-view'>
                                <li> Admission to this School is restricted by condition of age, ability and conduct. Applicants for admission will be carefully selected.</li>
                                <li> The age of admission to <b> NURSERY </b> will be <b> 3 year plus</b>.</li>
                                <li> For all the admissions to kindergarten classes ORIGINAL birth certificate, a photostat copy of Birth Certificate duly attested by a gazetted officer should be produced in the office.(Original birth certificate is returned to the parents after verification of the documents). At the time of admission, the students should be introduced personally by their parents/guardian who will be responsible for their studies, fees, conduct and discipline.</li>
                                <li> The Principal/Head Mistress reserves to herself the right of admission. </li>
                                <li> For more information on admission kindly contact the school office.</li>
                            </ul>
                            <h3>NURSERY FORMS for Session 2025-2026</h3>
                            <p>NURSERY CLASS Registration form will be available ONLINE on School website and on below mentioned campuscare10x link.   </p>
                            <p><b>ONLINE REGISTRATION FORM will be available from: </b></p>
                            <p><b>30th November 2024  at 10 am till 4th December 2024  upto 11 pm.</b></p>
                            <p><b>The link for registration form will be available at : <a href="https://entab.online/ncsraj" target="_blank"> https://entab.online/ncsraj</a> </b></p>
                            <p><b>(Note : The link will be available  at above url from 30th November 2024 at 10 am)</b></p>
                            <p><b>Eligibility:</b> The age of the child seeking admission to Nursery Class 2025-26 should be between 3 years and 4 years as on 01 June, 2025. If your child is NOT within the age limit specified, she will not be eligible for admission.</p>
                            <p><b>Procedure to fill the online registration form: -</b></p> 
                            
                            <p>For more details: Read the guideline attached in Latest News/ Highlights section.</p>
                        </div>
                        <div className="col-md-4">
                            <p>Procedure to fill the online registration form: -</p>
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/admission-registration.png">
                            <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/admission-registration.png" class="img-fluid admissionimg" alt=" Children's Convent School Rajkot" /></LazyLoad>
                            </a>
                            <p>For more details: Read the guideline attached in Latest News/ Highlights section.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default AdmissionProcedure;
import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from '../Service/Api';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const OnlineReg = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const onlineData = await getOnlineRegistration();
        setData(onlineData);
      } catch (error) {
        console.error("Error fetching online registration data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>  
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <div className="OnlineR" key={index}>
            <span className="bounce1">
              <Link to="https://entab.online/NCSRAJ" target="_blank" className="parantlogo">
                {item.title}
              </Link>
              {item.attachments.length> 0 && (<Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank" className="">
                <i className="bi bi-paperclip"></i>
              </Link>)}
              
            </span>
          </div>
        ))
      ) : null}
    </>
  );
}

export default OnlineReg;

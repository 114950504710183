import React, { useEffect, useState } from "react";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer';
import { useNavigate } from "react-router-dom";
import KindergartenMenu from "../Component/kindergartenMenu";
import LazyLoad from 'react-lazyload';
import { getGalleryInner } from '../Service/Api';

const SocialResponsibility = () => {
    
  const navigate = useNavigate();
    const [data, setData] = useState([]);  

    useEffect(() => {
        const fetchData = async () => {
            const galleryData1 = await getGalleryInner();
            const galleryData = galleryData1.filter(item => item.category === 'SocialResponsibility' && item.school === 'Junior');
            console.log(galleryData);
            setData(galleryData);
        };

        fetchData();
    }, []);

    // Date formatting function
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
    
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.log("Invalid Date format:", isoDate);
            return 'Invalid Date'; // If the date can't be parsed, return "Invalid Date"
        }
    
        // Format the date as DD MM YYYY
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if single digit
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-11, so add 1)
        const year = date.getFullYear();
    
        return `${day}.${month}.${year}`; // Return in DD MM YYYY format
    };
    const handleModal = id => {
        navigate(`/SubGallery?id=${id}`);
      };
    
  return (
    <>
      <KindergartenMenu />
      <Breadcrumb title="Social Responsibility" mid="Kindergarten &nbsp; &nbsp;|" tabName="Social Responsibility" />
      <div className="innerPage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Check if title exists before rendering */}
               <p><b>Becoming Responsible Citizens</b></p>
            </div>
          </div>
          <div className="row kidsactivities_sec_img min-height-section">
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount" key={index}>
                                    <div className="gal_new">
                                        <article>
                                            <LazyLoad>
                                                <img
                                                    src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}
                                                    alt={item.title || "Activity Image"}
                                                    className="img-fluid"
                                                />
                                            </LazyLoad>
                                            <div className="gal-content">
                                                <h4 className="catgry-name">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <h6 className="date">
                                                    <i className="bi bi-calendar-week"></i> {formatDate(item.date)}
                                                    <span>
                                                        <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}>
                                                            <i className="bi bi-arrow-right"></i>
                                                        </a>
                                                    </span>
                                                </h6>
                                                <div className="total-img">
                                                    <i className="bi bi-card-image"></i><p>{item?.attachments.length}</p>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount">
                                <div className="gal_new">
                                    <article>
                                        <LazyLoad>
                                            <img
                                                src="https://webapi.entab.info/api/image/NCSR/public/Images/kinder-about.png"
                                                alt="Nirmala Convent School"
                                                className="img-fluid"
                                            />
                                        </LazyLoad>
                                        <div className="gal-content">
                                            <h4 className="catgry-name">Title</h4>
                                            <p>Stay Tuned For More Updates</p>
                                            <h6 className="date">
                                                <i className="bi bi-calendar-week"></i> DD.MM.YYYY
                                                <span>
                                                    <i className="bi bi-arrow-right"></i>
                                                </span>
                                            </h6>
                                            <div className="total-img">
                                                <i className="bi bi-card-image"></i><p>0</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        )}
                    </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SocialResponsibility;

import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const ClassroomActivity = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Classroom Activities" mid="Activities/Life@NCS &nbsp; &nbsp;|" tabName="Classroom Activities" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Direct teaching as well as Interactive whole-class teaching in smart classrooms, Student-centred methods such as Project based learning, Inquiry based learning, Problem based learning, Resource based learning, Experiments, Debates, Presentations, Journals, Discussions, Field trips, Films, Games, Skits, Surveys etc. are used at various levels to facilitate the teaching-learning process in Nirmala School.</p>
                        </div>                     
                    </div>
                    <div class="row kidsactivities_sec_img">
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-1.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-2.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-3.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-4.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-5.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-6.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-7.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-7.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-8.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-8.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-9.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-9.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-10.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-10.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-11.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-11.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-12.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Classroom-12.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ClassroomActivity;
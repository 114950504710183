import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const ComingSoon = () => {
    return(
        <>
        <Header />
            <Breadcrumb title="Coming Soon" tabName="Coming Soon" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/coming-soon.jpg" alt="Nirmala Convent School Rajkot"  className="img-fluid m-auto d-block" /></LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ComingSoon;
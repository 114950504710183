import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const SocialCommunityService = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Social and Community Service" mid="Activities/Life@NCS &nbsp; &nbsp;|" tabName="Social and Community Service" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>The Nirmalites have always lent their helping hands across India, to people affected by natural calamities. The school has also taken up the challenge to teach underprivileged children especially from the slums.</p>
                            <h3>Eco Club & Social Service Club</h3>
                            <p>The social Service Club and the Art Club had organized “Best out of waste” Competition for the students. It was decided to display and sell the best article during PTM and the students could get more than 6000 rupees which was donated as charity for the senior citizens home.</p>
                            <p>The Social Service club organized an extempore competition for the students of Std.8. Banner making with message competition were organized for the HKG Section. It was mother child competition.</p>
                            <p>Our school collected fund for the Kerala Flood Relief. The school management appreciated the noble gesture of the students and teachers who rendered a helping hand towards donating for the flood affected people of Kerala. Mr. and Mrs. Gaurang Joshi, parents of one of our students led the task of pooling the materials in abundance for the flood victims of Kerala along with many other families in their vicinity</p>
                            <p>The Eco club organized a ‘Green Drive’ in the form of a student’s ‘cycle rally with posters and slogan. The purpose was to convey the message of ‘Save Earth’ to the citizen of Rajkot. The rally began from the school premises after taking the pledge and the ‘waving off’ of the green flag by Principal. The Eco club in-charge teachers had accompanied the students and were appreciated for this noble cause. The students prepared paper bags and distributed them to vegetable vendors who use plastic bags in abundance. Planting trees in the school campus was also a part of this drive. Every tree was named after a Martyrs (Jawans) who gave up their life for the ‘Mother Land’.</p>
                            <p>The Eco Club had organized a ‘Letter writing competition’ under the ‘Swachh Bharat Abhiyan’ intiated by CBSE. The letter was addressed to RMC.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-1.jpg" className="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a>  </LazyLoad></div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-2.jpg" className="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a>  </LazyLoad></div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4"><LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-3.jpg" className="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a>  </LazyLoad></div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-4.jpg" className="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a>  </LazyLoad></div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-5.jpg" className="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a>  </LazyLoad></div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4"><LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Eco-club-6.jpg" className="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>The nirmalites have always lent their helping hands across India, to people affected by natural calamities.</p>
                            <p>The school has also taken up the challenge to teach underprivileged children especially from the slums.</p>
                            <p><b>18/12/10 - </b>allowed the differently abled children of Navshakti Vidyalaya to display and sell the articles made by them in NCS. Nirmalites purchased things worth <b>Rs. 35200/ -</b></p>
                            <p><b>14/12/09 -</b> allowed the differently abled children of Navshakti Vidyalaya to exhibit and sell the things made by them for two days in NCS. Nirmalites purchased things worth <b>Rs. 22000/ -</b></p>
                            <p><b>6/12/08 - Rs. 32400/-</b> was contributed to Navshakti Trust by the students for the purpose of providing medical and nutritional support for the persons infected with HIV/AIDS.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <p><b>Supporting the children of domestic workers</b><br /> 20/07/08 - sent note books to Indore for the children of domestic workers.</p>
                            <p><b>Adding a ray of light in the lives of the Blind people</b><br /> 1/08/08 - Rs. 31000/- collected on behalf of the National Association for the Blind on the flag day.</p>
                            <p><b>This was the highest amount collected all over Gujarat.</b><br /> 14/09/11 - Rs. 56759/- donation for Blind Association.</p>
                            <p><b>Caring for the flood victims</b><br /> 24/11/08 - Rs. 40000/- sent for the flood victims of Bihar. Nirmala had reached out from very far to the suffering people who had lost everything. But the care and concern expressed showed that the flood victims were near…near to their hearts. Nirmalites valuable contribution brought smiles on their faces.</p>
                        </div>
                        <div class="col-lg-6">
                            <p><b>Caring for the hungry</b><br /> 2/04/09 - Rs. 5000/- towards the campaign against Hunger &amp; disease.</p>
                            <p><b>Bringing smiles in the lives of the less privileged children</b><br /> 12/08/09 - Rs. 6196/- donated to Smile Foundation as an expression of love &amp; care shown towards the underprivileged children of our country.</p>
                            <p><b>Helping to promote good literature</b><br /> 20/09/11 - students purchased books worth Rs. 38275/- from Book Exhibition of Pauline sisters Ahmedabad as part of collaboration in their mission of spreading and promoting Good literature and audio-visual materials.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Social_1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Social_1.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad> </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Social_2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Social_2.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/Social_3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/Social_3.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-lg-12">
                            <h3>Rallying for a green world</h3>
                            <p>In 2012-13, the Eco Club celebrated "Environment week" from July16 to July 21 wherein Rangla and Rangli visited the school to bring awareness on Saving the Environment. Special assembly, notice boards and a rally was conducted to emphasize on saving our planet by keeping it green and clean.</p>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-1.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-2.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-3.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-4.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-5.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">  <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/green-world-6.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default SocialCommunityService;
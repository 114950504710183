import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const KindergartenMenu = () => {
    return (
        <>
            <div className="header">

                <div className='container-fluid'>
                    <div className='row'>

                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-12'>
                            <div className="logo">
                                <NavLink to="/">
                                <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/logo.png" alt="Nirmala Convemt School Rajkot" className="img-fluid" /></LazyLoad>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <div className='header_list'>
                                <ul>
                                    <li><a href="tel:0281 - 2966204"><i class="bi bi-telephone-fill"></i> 0281 - 2966204 / 03</a></li>
                                    <li><a href="mailto:ncsrajkot@gmail.com"><i class="bi bi-envelope-fill"></i> ncsrajkot@gmail.com</a></li>
                                    <li><i class="bi bi-geo-alt-fill"></i> Dr Hedgewar Road, Rajkot - 360007</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='dark-box'>
                                <div>
                                    <Link to="/">
                                    <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten.gif" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                                        <p>Senior</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/magazine">
                                    <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/magazine.gif" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                                        <p>Magazine</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/newsletter">
                                    <LazyLoad>    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/newsletter.gif" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                                        <p>Newsletter</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='pphelp'>
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/pphelp.png" alt="Nirmala Convemt School Rajkot" /></LazyLoad>
                        </div>
                    </div> 
                </div>

                <div className="header-nav">

                    <nav className="navbar navbar-expand-lg">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="main_nav">
                            <ul className="navbar-nav">
                                <li className="nav-item active"> <NavLink className="nav-link" to="/kids-fee-details">Fee Details </NavLink> </li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/kids-strength">Students Strength </NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="https://webapi.entab.info/api/image/NCSR/public/pdf/FRC-ORDER.pdf" target='_blank'>FRC Order</NavLink></li>
                                {/* <li className="nav-item"> <NavLink className="nav-link" to="/holy-spirit">Holy Spirit</NavLink></li> */}
                                <li className="nav-item"> <NavLink className="nav-link" to="/kids-uniform">Uniform</NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/kinder-activities">Activities</NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/cultural-programm">Cultural Program</NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/goals-rules">Goals & Rules</NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/awards">Awards</NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/kids-sports">Sports</NavLink></li>
                                <li className="nav-item"> <NavLink className="nav-link" to="/social-responsibility">Social Responsibility</NavLink></li>                                
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default KindergartenMenu

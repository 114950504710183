import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer' 
const CoCurricular = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="Co-curricular" mid="Activities/Life@NCS &nbsp; &nbsp;|" tabName="Co-curricular" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>In order to assist the students to develop their whole personality, Nirmala School gives emphasis on various co-curricular activities.</p>
                            <p><b>Some of the activities in which students participate are:</b></p>
                        </div> 
                        <div className="col-md-12">
                            <ul className='list-view'>
                                <li>National Science and International Maths Olympiads</li>
                                <li>National Emats and GK Olympiads</li>
                                <li>Declamation, skits, elocutions, debates, powerpoint presentations, reading, essay writing competition etc. to enhance vocabulary, pronunciation, voice modulations and comprehension in English language.</li>
                                <li>Science Exhibitions and science fairs</li>
                                <li>Viewing of Planets and Stars</li>
                                <li>Leadership Seminars</li>
                                <li>Field trips to museums, planetarium, factories, zoo etc.</li>
                                <li>Training on how to conduct a small level research, especially on data gathering.</li>
                            </ul>             
                        </div>
                    </div>                    
                </div>
            </div>
            <Footer />
        </>
    )
}
export default CoCurricular;
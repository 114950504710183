import React from 'react'
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
const KindergartenSlider = () => {
    const settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        adaptiveHeight: true,

    };
    return (
        <>
            <Slider {...settings}>
                <div className="carousel-item active">
                <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten1.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten2.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                  <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten3.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                   <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten4.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                  <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten5.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                   <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten6.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                  <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten7.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
                <div className="carousel-item">
                  <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/kindergarten8.jpg" className="d-block w-100" alt="Nirmala School Rajkot" /></LazyLoad>
                </div>
            </Slider>
        </>
    )
}

export default KindergartenSlider

import Header from "../Component/Header";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const Newsletter = () => {
    return(
        <>
        <Header />
            <Breadcrumb title="Newsletter" tabName="Newsletter" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/coming-soon.jpg" className="img-fluid m-auto d-block" alt="Nirmala Convent School Rajkot" /></LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Newsletter;
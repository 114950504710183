import React, { useEffect, useState } from 'react'
import { getTestimonial } from '../Service/Api';
import LazyLoad from 'react-lazyload';
import Slider from "react-slick"; 
const Testimonial = () => { 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const testimonialData = await getTestimonial(); 
        setData(testimonialData);
      } catch (error) {
        console.error("Error fetching gallery data:", error); 
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <> 
        <Slider {...settings}>
        {data.length>0? data.map((item, index) => (
          <div className="item" key={index}>
            <div className='testimonial-blk'>
                <div class="testimonial-head">
                <LazyLoad>   <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                    <div>
                        <h4>{item.title}</h4>
                        <p>{item.designation}</p>
                    </div>
                </div>
                <div>
                    <p>{item.description}</p>
                </div>
            </div>
          </div>
           )):(
            <div className="item">
            <div className='testimonial-blk'>
                <div class="testimonial-head">
                <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/dummy-img.jpg" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                    <div>
                        <h4>Tim Burner</h4>
                        <p>Strategy Planner</p>
                    </div>
                </div>
                <div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                </div>
            </div>
          </div>
          )}
          
        </Slider> 
    </>
  )
}

export default Testimonial
import React, { useState } from 'react';
import Header from '../Component/Header'
import Breadcrumb from '../Component/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import { postTransferCertificate } from '../Service/Api';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';

const TC = () => {
  const navigate = useNavigate();
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError("");
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        fetch(`https://webapi.entab.info/api/image/${response.data}`)
          .then(response => response.blob()) 
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => alert('Failed to download data.'));
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
    <Header />
      <Breadcrumb title="TC Issued"  tabName="TC Issued" />
      <div className="innerPage">
        <div className="container">
          <div className='row'>
            <div className="col-md-6">
              <div className="tcblock">
                <div className="tc">
                  <div>
                    <div className="formfil">
                      <form onSubmit={handleSubmit} className="tc">
                        <div className="text_box_sec form-group">
                          <label htmlFor="admission">Admission No.</label>
                          <input
                            type="text"
                            placeholder="Admission Number"
                            id="admission"
                            className='form-control'
                            value={admission}
                            onChange={handleAdmissionNoChange}
                          />
                        </div>
                        <div className="text_box_sec form-group">
                          <label htmlFor="name">Student Full Name</label>
                          <input
                            type="text"
                            placeholder="Student Name"
                            id="name"
                            className='form-control'
                            value={name}
                            onChange={handleFullNameChange}
                          />
                        </div>
                        <div className="text_box_sec">
                          <input
                            type="submit"
                            name="btnshwtcdetails"
                            value="View Details"
                            id="btnshwtcdetails"
                            className="btn  viewdetailsbtn"
                          />
                        </div>
                        {error && <p className="error">{error}</p>}
                      </form>
                    </div>
                    <div className="clr30"></div>
                  </div>
                </div>

              </div>
            </div>
            <div className='col-md-6'>
              <iframe src='https://webapi.entab.info/api/image/NCSR/public/pdf/TC-Sample.pdf#toolbar=0' width="100%" height="500" title="Transfer Certificate Sample"></iframe>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default TC;

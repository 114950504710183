import Header from "../Component/Header";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const OurPatronees = () => {
    return(
        <>
        <Header />
            <Breadcrumb title="Our Patroness" mid="About Us &nbsp; &nbsp;|" tabName="Our Patroness" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-8"> 
                            <p>Nirmal Maria, the Immaculate Mother of Jesus is the patroness of Nirmala Convent School. She was sensitive to the needs of others and took initiative to serve them. She was a woman of faith. She teaches us to be attuned to God's voice in our lives and to be bearers of the Divine presence wherever we are. Through her example, She encourages us to be brave and face the unknown future.</p>
                            <p>For Mary, God's intervention in history was a reality! May we like Mary, have courage as we actively participate in shaping our history anew. She is our mother and guiding star. We turn to her in every need, trusting that She will always stand by us.</p>
                        </div>
                        <div className="col-md-4">
                        <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/Patroness.png" alt="Nirmala Convent School Rajkot"  className="img-fluid" /></LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default OurPatronees ;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";  
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';

const SubGallery = () => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    // Navigate back to the previous page
    navigate(-1);  // Equivalent to window.history.back()
  };

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]); // Add id as a dependency

  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://webapi.entab.info/api/image/${img}`
  }));

  return (
    <>
      <Header /> 
      <Breadcrumb title={data.title} tabName={data.title} />
      <div className="innerPage">
        <div className="container">
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div className="shrink">
                <Link onClick={handleBackButtonClick}>
                  <i className="bi bi-arrow-left"></i> Go Back
                </Link>
              </div>
            </div>  
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div><b>Event Date:</b> {new Date(data.date).toLocaleDateString("en-GB")}</div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div className="month-selection">
                <div><b>Updated On:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div className="month-selection">
                <div><b>Total Photos:</b> {data?.attachments?.length}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="fulwidth">
                <div className="discription"> 
                  <p>{data.description}</p>
                </div>
              </div>
              <div className="sub_gallery row">
                {data && data.attachments && data.attachments.length > 0 && (
                  data.attachments.map((img, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                      <a data-magnify="gallery" href={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}>
                        <LazyLoad>
                          <img src={`https://webapi.entab.info/api/image/${img}`} className="img-fluid" alt={`Image ${index}`} />
                        </LazyLoad>
                      </a>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer /> 
    </>
  );
};

export default SubGallery;

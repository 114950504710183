import React from 'react' 
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
const HomeSlider = () => {
  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 4000,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
 
  };
  return (
    
     <>
     <div id="carouselExampleControls" className="carousel slide zoomIn" data-bs-ride="carousel">
     <div className="carousel-inner">
     <Slider {...settings} > 
             <div className="carousel-item active">
             <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide2.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide3.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide4.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide5.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide6.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide7.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide8.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad> <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide9.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div>
           <div className="carousel-item">
           <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/slide10.jpg"  className="d-block w-100" alt="Nirmala School Rajkot"/></LazyLoad>
           </div> 
           </Slider>
     </div>
     
 </div>


     </>
  )
}

export default HomeSlider

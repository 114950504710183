import { useState } from 'react';
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';

const Sports = () => {
    const [activeTab, setActiveTab] = useState(1); // State for the active tab
    const [activeSection, setActiveSection] = useState('sports'); 
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    return (
        <>
        <Header />
            <Breadcrumb title="Sports" mid="Activities/Life@NCS &nbsp; &nbsp;|" tabName="Sports" />
            <div className="innerPage">
                <div className="container">
                    <div className='row'>
                        <div className="col-md-12">
                            <ul className='sports-tab'>
                                <li className={activeSection === 'sports' ? 'active' : ''} onClick={() => setActiveSection('sports')}>Sports</li>
                                <li className={activeSection === 'summerCamps' ? 'active' : ''} onClick={() => setActiveSection('summerCamps')}> Summer Camps</li>
                            </ul>
                        </div>
                    </div> {activeSection === 'sports' && (
                    <div className="row sports-tab-select">
                        <div className="col-md-12">
                            <div className="sports">
                                <p>
                                    In order to enhance the mental, physical and psychological development of individual students, the school lays emphasis in physical education, especially on sports and games. Through exercises, yoga, sports and games, students are also trained to follow strict discipline, fair play, unity and co-operation. Above all, they also develop positive attitude and a challenging spirit to face their future.
                                </p>
                                <p>Team spirit and true sportsmanship are the mantra for sports in Nirmala.</p>
                                <p>They participate in competitions held at various levels like inter-school, districts, states and national level.</p>
                            </div>
                        </div> 
                        <div className="row kidsactivities_sec_img">
                            {['sports1.jpg', 'sports2.jpg', 'sports3.jpg', 'sports4.jpg'].map((img, idx) => (
                                <div key={idx} className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <a data-magnify="gallery" data-src="" data-group="a" href={`/Images/${img}`}>
                                    <LazyLoad> <img src={`https://webapi.entab.info/api/image/NCSR/public/Images/${img}`} className="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></LazyLoad>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-md-12 tab-selection-list">
                                {['Basketball', 'Football', 'Kabaddi', 'Gymnastics', 'Swimming', 'Table Tennis'].map((tabName, index) => (
                                    <a
                                        key={index}
                                        className={`tabs-select ${activeTab === index + 1 ? 'active' : ''}`}
                                        onClick={() => handleTabClick(index + 1)}
                                    >
                                        {tabName}
                                    </a>
                                ))}
                            </div>

                            {/* Tab Content */}
                            <div className="col-md-12">
                                {activeTab === 1 && (
                                    <div className="tabs-select1 active" data-trigger="1">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Academic Year</th>
                                                    <th>2011-12</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 19</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Interschool Champions</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>State level competition held at Bhuj (2nd place)</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>Apurva Kaluskar, Ameshi Parekh and Shenee Ashara were selected for the National level competition held at Delhi</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Women (opens)</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Interschool Champions</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>State level competition held at Himmatnagar (3rd Place)</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>Apurva Kaluskar and Ameshi Parekh were selected for the National level competition held at Bhopa</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Played Mahakumbh Tournament</strong><br />
                                                        Category</td>
                                                    <td>Under - 16</td>
                                                </tr>
                                                <tr>
                                                    <td>Result </td>
                                                    <td>Interschool Champions</td>
                                                </tr>
                                                <tr>
                                                    <td>- </td>
                                                    <td>State level competition held at Bhavnagar</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Women (opens)</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Interschool Champions </td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 14 </td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Interschool 1st runners-up </td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>State level competition held at Baroda ( 2nd Place) </td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 16</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td> Interschool 1st runners-up</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Academic Year </th>
                                                    <th>2012-13</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 19</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Winner (Friendly Match)</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>NCS Vs. RKC</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 19</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>2nd Rank</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>NCS Vs. SNK</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 17</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>District Champion</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>NCS Vs. SNK</td>
                                                </tr>
                                                <tr>
                                                    <td>Category </td>
                                                    <td>Womens</td>
                                                </tr>
                                                <tr>
                                                    <td>Result </td>
                                                    <td>District Runner ups</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>NCS Vs. SNK</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 14 </td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Lost in Semi Finals </td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>NCS Vs. St. Paul's School</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {activeTab === 2 && (
                                    <div className="tabs-select1" data-trigger="2">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th width="500px">Academic Year</th>
                                                    <th width="500px">2011-12</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 17</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>State level champions</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>* Nehal Bhatt, Fiona Pandyan, Keziah Monichen and Niddhi were selected for the National level competition held at Imphal</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>Played Mahakumbh Tournament</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 16</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>Interschool Champions</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>State level competition held at Ankleshwar</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Women (opens)</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">3 teams of our school participated</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {activeTab === 3 && (
                                    <div className="tabs-select1" data-trigger="2">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th width="500px">Academic Year</th>
                                                    <th width="500px">2012-13</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 17</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>District Champions</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>NCS Vs. KVKV</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 17</td>
                                                </tr>
                                                <tr>
                                                    <td>Result </td>
                                                    <td>Lost in 2nd Match</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {activeTab === 4 && (
                                    <div className="tabs-select1" data-trigger="2">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th width="500px">Academic Year</th>
                                                    <th width="500px">2012-13</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 14</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>5th Rank</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Selected for State level Tournament</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {activeTab === 5 && (
                                    <div className="tabs-select1" data-trigger="2">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th width="500px">Academic Year</th>
                                                    <th width="500px">2012-13</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 14</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>General Champion</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 17</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>General Champion</td>
                                                </tr>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 19</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>Result</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {activeTab === 6 && (
                                    <div className="tabs-select1" data-trigger="2">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Academic Year</th>
                                                    <th>2012-13</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Category</td>
                                                    <td>Under - 17</td>
                                                </tr>
                                                <tr>
                                                    <td>Result</td>
                                                    <td>2nd Rank</td>
                                                </tr>
                                                <tr>
                                                    <td>-</td>
                                                    <td>1 student selected for State level Tournament</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="row kidsactivities_sec_img">
                                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sports5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sports5.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </div>
                                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sports6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sports6.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </div>
                                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sports7.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sports7.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </div>
                                            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3"> <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/sports8.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/sports8.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></a> </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                     )}
                      {activeSection === 'summerCamps' && (
                    <div className="row summerCamp-tab-select">
                        <p>The summer camps are the highlights of our summer holidays. The children participate in various activities like drawing &amp; painting, music, sports etc.</p>
                    </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Sports;

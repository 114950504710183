import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const CoScholastic = ()=>{
    return(
        <>
              <Header />
            <Breadcrumb title="Origin & History" mid="About Us &nbsp; &nbsp;|" tabName="Origin & history" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/history.jpg" className="img-fluid border_box" alt="Nirmala Convent School Rajkot" /></LazyLoad>
                        </div>
                        <div className="col-md-6"> 
                            <p><b>"Anterior to life stands service, posterior to death stands love, initial of creation and end of destruction, all lives lay in the foundation of love."</b></p>
                            <p>The first act in the history of Nirmala Convent School was played out in far away Valencia, in Spain, when the newly appointed Bishop of Ahmedabad, Rev. Monsignor Edwin Pinto, on a visit to his alma mator, persuaded the Bishop of Valencia to send some Spanish nuns to work in Rajkot. It was the involvement of the sisters of the Congregation of the Missionary Sisters of Christ Jesus including Sr. Maria Theresa Unzu in the educational field in Rajkot that paved the way for the establishment of Nirmala Convent School, on 14th June 1963.</p>
                            <p>The school opened with classes from Nursery to standard III at Koyna House and had initially some 170 girls. The school laid down its permanent roots in Kalawad Road in 1970 and the first batch of students were presented for the SSC examinations in both English and Gujarati mediums in the year 1971. The ownership and management of the school was handed over by the Sisters of Christ Jesus to the Missionary Sisters Servants of the Holy Spirit on 1st April, 1985. Sr Rita took over the charge as the Principal of the school.</p>
                            <p>At present, Nirmala proudly takes the responsibility of providing quality education and formation to more than 3300 girls. Nirmala Convent School has made a journey, through long and winding ways in the field of education and stood the ground during testing times, changing the mind set of the society towards the group called 'weaker sex' by empowering them to prove that they are strong in their resolve to be second to none in the society.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default CoScholastic;
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const Headmistress = ()=>{
    return(
        <>
              <Header />
            <Breadcrumb title="Headmistress  Message" mid="About Us &nbsp; &nbsp;|" tabName="Headmistress Message" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                        <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/headmistress.png" className="img-fluid border_box" alt="Nirmala Convent School Rajkot" /></LazyLoad>
                        </div>
                        <div className="col-md-8"> 
                            <p><b>Motto: "Love in Service"</b></p>
                            <p>At Nirmala, we believe that education transcends academics; it is the art of nurturing the heart, mind, and soul. Each child who enters our institution is a unique gift from God, brimming with untapped potential. Our mission is to inspire in our students a passion for lifelong learning, a commitment to excellence, and a deep sense of responsibility to make the world a better place through love and compassion.</p>
                            <p>It is our privilege to ignite and nurture the spark of "Love in Service," transforming it into a flame of confidence, creativity, and character—a flame where dreams take flight and futures are shaped with purpose and determination.</p>
                            <p>Together, as parents, teachers, and students, let us embark on this journey, embracing every opportunity to grow, innovate, and excel for the brighter future of our students.</p>
                            <h6><span>SR. SHANTA</span></h6>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Headmistress;
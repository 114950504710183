import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const About = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Co-Scholastic Activity" mid="Activities/Life@NCS &nbsp; &nbsp;|" tabName="Co-Scholastic Activity" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ul className='list-view'>
                                <li>Thematic Assembly</li>
                                <li>Celebration Of Major Festivals</li>
                                <li>Value Education Programmes</li>
                                <li>Awareness Programmes on Social and Civic Issues</li>
                                <li>Career Guidance Programmes</li>
                                <li>Science Projects, Visual and Performing Arts</li>
                                <li>Computer Training</li>
                                <li>Leadership Programme</li>
                                <li>Yoga</li>
                                <li>Club Activities</li>
                                <li>Notice Board Competitions</li>
                                <li>House System</li>
                                <li>School Parliament</li>

                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul className='list-view'>
                                <li>Annual School Magazine</li>
                                <li>Sports Coaching</li>
                                <li>School Band</li>
                                <li>Charity Work</li>
                                <li>Painting, Music & Dance Activities</li>
                                <li>E-Learning</li>
                                <li>Awareness Programmes on General Health</li>
                                <li>Eco Club</li>
                                <li>Counselling</li>
                                <li>Cultural & Sports Activities</li>
                                <li>Gujarati, Hindi and English literary Activities</li>
                                <li>Language Lab</li>
                                <li>Maths Lab Activities</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About;
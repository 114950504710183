import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer' 
const Magazine = ()=>{
    return(
        <>
        <Header />
            <Breadcrumb title="School Magazine" tabName="School Magazine" />
            <div className="innerPage">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12"> 
                            <p><b>Beginning of a creative future.</b></p>
                            <p>Nirmalight - The School Magazine was launched in 2008. As the name suggest, it brings you the sparkle of the nirmal (pure) light of wisdom of Nirmalights. It is a milestone and mean different things to different persons. It is a platform to bring out the latent talents of the students and encourage them to fine their feet in the literary world. The magazine was aimed to help the students in this infotech world to think faster, formulate and express ideas quickly and to develop a creative imagination to solve problems and respond to various issues of the society.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Magazine;
import Header from "../Component/Header";
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const YearPlanner = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Year Planner" mid="About Us &nbsp; &nbsp;|" tabName="Year Planner" />
            <div className="innerPage">
                <div className="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <iframe src="https://webapi.entab.info/api/image/NCSR/public/pdf/Year-Planner.pdf" width="100%" height="800"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default YearPlanner;
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const OurPatronees = () => {
    return(
        <>      <Header />
            <Breadcrumb title="Administration" mid="Academic &nbsp; &nbsp;|" tabName="Administration" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>NIRMALA CONVENT SCHOOL is administered by the ‘Nirmala Society’ belonging to the Sisters, Servants of the Holy Spirit (SSPS), a congregation of sisters belonging to the Catholic Church.</p>
                            <p>While the school aims at providing comprehensive education to the Catholic students, it wholeheartedly welcome students of all faiths and respects their freedom of conscience. We provide Value Education for all which is aimed at developing right attitude among pupils.</p>
                            <p>The school promotes integrated development of the children entrusted to its care, in the intellectual, academic, moral, emotional, aesthetic and physical spheres. Hence, we emphasize on games sports, drills, dramatics, fine arts, folk arts, social services and computer literacy, among a host of activities which the students are encouraged to take part.</p>
                            <p>The school takes the name from Nirmal Maria (the mother of Jesus) the embodiment of purity. Purity of mind and spirit is especially inculcated within the portals of Nirmala.</p>
                            <p>The motto “Love in Service” prompts the students to live always sensitive to the needs of others in a spirit of total availability.</p>
                        <h3>Admission</h3>
                        <p>Admission to Nirmala Convent School is governed by certain government regulations and by conditions regarding age, ability and conduct. Applicants will be examined and admitted to the standards for which they are found fit.</p>
                        <p>The admission is incomplete unless the Leaving Certificate from the last school attended by the applicant is produced.</p>
                        <p>The management reserves the right to reject any application without having to assign reasons for it.</p>
                        <h3>WITHDRAWALS</h3>
                        <p>School Leaving Certificate will not be issued unless applied for in writing by the parents or guardian and after all the dues have been paid. If the Leaving Certificate is not asked for within a year of cessation of he studies, Rs. 100/- is charged for it. The same amount is payable for issue of a duplicate certificate. . Parent should apply for any certificate viz, bonafide, no objection, duplicate certificate etc at least three days prior to the date of collection.</p>
                        <h3>OFFICE HOURS: </h3>
                        <p>For any administrative work contact school main office on working days  between: </p>
                        <ul className='list-view'>
                            <li>8:00 am to 1:00 pm (on Monday to Friday)</li>
                            <li>8:00 am to 12:30 pm (on Saturdays)</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default OurPatronees;
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
const SchoolParliament = () => {
    return (
        <>
        <Header />
            <Breadcrumb title="School Parliament" mid="About Us &nbsp; &nbsp;|" tabName="School Parliament" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
                            <p><b>Student Leadership And Participation</b></p>
                            <p>The school has a unique system of self governance. Through the School Council, the school parliament aims to promote democratic practices, to develop participatory leadership and to promote an environment conducive to educational and personal developments.</p>
                            <p><b>The school parliament consists of the elected representatives such as</b></p>
                            <ul className='list-view'>
                                <li> Head Girl </li>
                                <li> Vice-Head Girl </li>
                                <li> Council of Four Ministers </li>
                                <li> Main House Captains and Vice-House Captains of four Houses (namely Blue, Green, Red and Yellow) </li>
                                <li> Prefects and Disciplinarians </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row kidsactivities_sec_img">
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_1.jpg">
                            <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_1.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_2.jpg">
                            <LazyLoad>    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_2.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_3.jpg">
                            <LazyLoad>    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_3.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_4.jpg">
                            <LazyLoad>    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_4.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_5.jpg">
                            <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_5.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_6.jpg">
                            <LazyLoad>    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_6.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_7.jpg">
                            <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_7.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_8.jpg">
                            <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_8.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_9.jpg">
                            <LazyLoad>    <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_9.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_10.jpg">
                            <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_10.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_11.jpg">
                            <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_11.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_12.jpg">
                            <LazyLoad>   <img src="https://webapi.entab.info/api/image/NCSR/public/Images/parliament_12.jpg" class="img-fluid midimg" alt=" Nirmala Convent School Rajkot" /></LazyLoad> 
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default SchoolParliament;
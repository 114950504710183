import React, { useState } from 'react';
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import { form } from '../Service/Api';
import { getHeader } from "../config"
import LazyLoad from 'react-lazyload';

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return re.test(email);
};

const validatePhone = (phone) => {
  const re = /^\d{10}$/;  // Adjust regex according to your needs
  return re.test(phone);
};
const Contact = () => {

    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState('');
  
    const validateForm = () => {
      let errors = {};
      let formIsValid = true;
  
      if (!formData.name) {
        errors["name"] = "Name cannot be empty";
        formIsValid = false;
      }
  
      if (!formData.email) {
        errors["email"] = "Email cannot be empty";
        formIsValid = false;
      } else if (!validateEmail(formData.email)) {
        errors["email"] = "Invalid email address";
        formIsValid = false;
      }
  
      if (!formData.phone) {
        errors["phone"] = "Phone number cannot be empty";
        formIsValid = false;
      } else if (!validatePhone(formData.phone)) {
        errors["phone"] = "Invalid phone number, must be 10 digits";
        formIsValid = false;
      }
  
      if (!formData.message) {
        errors["message"] = "Message cannot be empty";
        formIsValid = false;
      }
  
      setErrors(errors);
      return formIsValid;
    };
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (validateForm()) {
      try {
        const type="dif";
         const { header } = getHeader(); // Retrieve headers from getHeader function
         const response = await form(formData, header, type); // Pass the headers to the form function
       
         setSubmitStatus('Thank you for your feedback!');
         setFormData({ name: '', email: '', phone: '', message: '' }); 
          
      } catch (error) {
         console.error('Error submitting form:', error); 
      } setErrors({}); // Clear errors on successful submission
        } else {
          setSubmitStatus('Please fill all fields correctly.');
        }
   };
  

    return (
        <>
        <Header />
            <Breadcrumb title="Contact" tabName="Contact" />
            <div className="innerPage contactUs">
                <div className="container">
                    <div className="row">
                        <div class="col-lg-6">
                            <div class="contactinfo">
                                <div class="contactinfobox">
                                    <i class="bi bi-geo-alt-fill"></i>
                                    <div>
                                        <h5>Nirmala Convent School </h5>
                                        <p><a href="https://maps.app.goo.gl/hhGzUpnewRMg2QTa8" target="_blank"> Dr Hedgewar Road, Rajkot - 360007 </a></p>
                                    </div>
                                </div>
                                <div class="contactinfobox">
                                    <i class="bi bi-telephone-fill"></i>
                                    <div>
                                        <h5>For Nursery to HKG:  </h5>
                                        <p><a href="tel: 0281-2963660"> 0281-2963660</a> , <a href="tel:9638036608"> 9638036608</a></p>
                                    </div>
                                </div>
                                <div class="contactinfobox">
                                    <i class="bi bi-telephone-fill"></i>
                                    <div>
                                        <h5>For Class 1st to 4th </h5>
                                        <p><a href="tel: 0281-2966204"> 0281-2966204</a> , <a href="tel:9406704436"> 9406704436</a></p>
                                    </div>
                                </div>
                                <div class="contactinfobox">
                                    <i class="bi bi-telephone-fill"></i>
                                    <div>
                                        <h5>For Class 5th to 12th</h5>
                                        <p><a href="tel: 0281-2966203"> 0281-2966203 </a> , <a href="tel:9714036608"> 9714036608</a></p>
                                    </div>
                                </div>
                                <div class="contactinfobox">
                                    <i class="bi bi-envelope-fill"></i>
                                    <div>
                                        <h5>Email :</h5>
                                        <p><a href="mailto:ncsenquiry@gmail.com"> ncsenquiry@gmail.com </a> <br /> <b> Admission enquiry email on  </b>   : <a href="mailto:ncsenquiry@gmail.com"> ncsenquiry@gmail.com</a>
                                        <br /><b>Job Vacancy email  on </b>  :  <a href="mailto: hrschoolrecruitment2024@gmail.com">hrschoolrecruitment2024@gmail.com</a>
                                        <br /><b>Admin Office related work </b>:  <a href="mailto: ncsrajkot@gmail.com"> ncsrajkot@gmail.com</a></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            
                            <form  onSubmit={handleSubmit}>
                            <center><h3>CONTACT US</h3></center>
                                <div>
                                    <label>Your Name</label>
                                    <input type="text" placeholder="Full Name" name="name" value={formData.name} onChange={handleChange} />
                                    {errors.name && <div className="text-danger">{errors.name}</div>}
                                </div>
                                <div>
                                    <label>Email Id</label>
                                    <input type="email" placeholder="Email Id" name="email" value={formData.email} onChange={handleChange} />
                                    {errors.email && <div className="text-danger">{errors.email}</div>}
                                </div>
                                <div>
                                    <label>Mobile Number</label>
                                    <input type="tel" placeholder="Mobile Number" name="phone" value={formData.phone} onChange={handleChange}/>
                                    {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                </div>
                                <div>
                                    <label>Message</label>
                                    <textarea row="4" name="message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <div>
                                    <input type="submit" value='SUBMIT'></input>
                                    {submitStatus && <div className="feedback">{submitStatus}</div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Contact;